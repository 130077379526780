// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

@import "src/@fuse/scss/fuse";

@import "src/app/layout/scss/snack-bar";




// Hide noData row
.hide {
  display: none;
}

// password mask
.pw-mask {
  text-rendering: disc;
  -webkit-text-security: disc;
  -mox-text-security: disc;
}

.mat-tab-label {
    // border-top: solid 1px;
    // background-color: #B2EBF2 !important;
    color: rgb(0, 0, 0);
    opacity: 1;
    // font-weight: 900 !important;
}
// .mat-dialog-container {
//   padding: 0px !important;
// }