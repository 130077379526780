// Style for SnackBarService
.success-message {
    color: #270 !important;
    background-color: #dff2bf !important;
    .mat-simple-snackbar-action {
        color: #270 !important;
    }
}
.error-message {
    color: #d8000c !important;
    background-color: #ffbaba !important;
    .mat-simple-snackbar-action {
        color: #d8000c !important;
    }
}
.warn-message {
    color: #9f6000 !important;
    background-color: #feefb3 !important;
    .mat-simple-snackbar-action {
        color: #9f6000 !important;
    }

   
}

.notifier {
    border-radius: 21px !important;
    background-color:#006064 !important;
    .mat-simple-snackbar-action {
        color: #9f6000 !important;
    }
}